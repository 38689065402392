import React, { useState } from "react";
import "./testimonials.css";
import NuraImage from "../../assets/photos/nura.jpg";
import IsmailImage from "../../assets/photos/ismail.jpg";

const testimonials = [
  {
    quote:
      "This app has been a game changer for managing my academic workload.",
    author: "Ismail Mahadi",
    image: IsmailImage, // Replace with the correct path to the image
  },
  {
    quote: "I love how SafeSpace keeps me focused and organized!",
    author: "Nura Muhammad",
    image: NuraImage, // Replace with the correct path to the image
  },
  // Add more testimonials as needed
];

const TestimonialSection = () => {
  const [current, setCurrent] = useState(0);

  const nextTestimonial = () => {
    setCurrent((current + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrent((current - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="testimonial-section">
      <div className="testimonial-container">
        <div className="testimonial-content">
          <img
            src={testimonials[current].image}
            alt={testimonials[current].author}
            className="testimonial-image"
          />
          <blockquote className="testimonial-quote">
            "{testimonials[current].quote}"
          </blockquote>
          <p className="testimonial-author">- {testimonials[current].author}</p>
        </div>
        <div className="testimonial-arrow left-arrow" onClick={prevTestimonial}>
          &lt;
        </div>
        <div
          className="testimonial-arrow right-arrow"
          onClick={nextTestimonial}
        >
          &gt;
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
