import React from "react";
import "./landingPage.css";
import SafespaceApp from "../../safespace.apk";

const LandingPage = () => {
  return (
    <div className="app">
      <header className="app-header">
        <div className="app-logo">
          <h1>SafeSpace</h1>
        </div>
        <nav className="app-nav">
          <a href="#pricing">Pricing</a>
        </nav>
      </header>

      <main className="app-main">
        <h2>Connect, Collaborate, and Stay Organised with SafeSpace App</h2>
        <p>
          Enhance your academic journey with AI-powered tools to stay focused
          without unrelated social media distractions.
        </p>
        <a href={SafespaceApp} download className="download-btn">
          Download App
        </a>
        <div className="app-rating">
          <span>★★★★★</span> Students | AI
        </div>
        <blockquote className="testimonial">
          “Simplifies my academic life.”
          <br />— Ismail Mahadi
        </blockquote>
      </main>
    </div>
  );
};

export default LandingPage;
