import React from "react";
import "./pricing.css"; // Import your CSS file

const PricingSection = () => {
  return (
    <section className="pricing-section" id="pricing">
      <h2 className="pricing-title">Our Plan</h2>
      <p className="pricing-subtitle">
        Enjoy a one-month free trial with all features available.
      </p>
      <div className="pricing-plans">
        <div className="plan monthly-plan">
          <h3>Monthly Plan</h3>
          <p className="price">₦900</p>
          <p className="plan-description">Billed every month.</p>
        </div>
      </div>
      <p className="trial-note">Start with a 1-month free trial.</p>
    </section>
  );
};

export default PricingSection;
